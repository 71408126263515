import React from "react";

export default ({overlayInitiated}) => {
  return (
    <footer id="contact">
      <div className={overlayInitiated ? "footer-message-container-noPosition" : "footer-message-container"}>
        <h1>Lets work together</h1>
        <div className="footer-message-icons">
          <a href="mailto:renzoregio@gmail.com">
            <i class="far fa-envelope"></i>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/renzo-miguel-regio-aab598126/"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a target="_blank" href="https://github.com/RenzoRegio">
            <i class="fab fa-github"></i>
          </a>
        </div>
      </div>
      <div className={overlayInitiated ? "footer-contents-container-noPosition" : "footer-contents-container" }>
        <h1 className="footer-logo">R</h1>
        <div className="footer-tagline">
          <h1>Learning & growing one day at a time</h1>
        </div>
        <div className="footer-icons-container">
          <a target="_blank" href="https://renzoregio.medium.com/">
            <i class="fab fa-medium-m"></i>
          </a>
          <a
            target="_blank"
            href="https://teamtreehouse.com/renzoregio"
            className="footer-treehouse-icon"
          >
            <img src="/images/Treehouse-Logo.png" />
          </a>
          
        </div>
        <div className="footer-by-me">
          <span>&copy; Made by me</span>
        </div>
      </div>
    </footer>
  );
};
