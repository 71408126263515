import React, { useState } from "react";

// Components
import Splash from "./components/Splash";
import Bar from "./components/Bar";
import Header from "./components/Header";
import About from "./components/About";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

export default () => {
  const [displayContent, setDisplayContent] = useState(false);
  const [overlayInitiated, setOverlayInitiated] = useState(false);

  function changeOverlayInitiatedStatus(val){
    setOverlayInitiated(val)
  }
  setTimeout(() => {
    setDisplayContent(true);
  }, 1000);
  return (
    <div className="container">
      <Splash />
      {displayContent && (
        <div className="contentHandler">
          {/* <Bar /> */}
          <Header />
          <About />
          <Projects changeOverlayInitiatedStatus={changeOverlayInitiatedStatus} />
        </div>
      )}
      <Footer overlayInitiated={overlayInitiated}/>
    </div>
  );
};
