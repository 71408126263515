import React, { useState, useEffect } from "react";

export default () => {
  const [loadingDone, setLoadingDone] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      const dots = document.querySelectorAll(".loadingDots");
      let time = 0;
      for (let i = 0; i < dots.length; i++) {
        time += 0.2;
        dots[i].style.animationDelay = `${time}s`;
      }
    }, 100);
  
    setTimeout(() => {
      setLoadingDone(true)
    }, 2000)
  }, [])
  return (
    <div className={!loadingDone ? "splashContainer" : "removeDisplay"}>
      <div className={!loadingDone ? "loadingContainer" : "removeDisplay"}>
        <h1>Loading</h1>
        <h1 className="loadingDots">.</h1>
        <h1 className="loadingDots">.</h1>
        <h1 className="loadingDots">.</h1>
      </div>
      <div className={!loadingDone ? "barContainer" : "removeDisplay"} >
        <div className="progressBar"></div>
      </div>
    </div>
  );
};
