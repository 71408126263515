import React, { useState } from "react";

export default () => {
  // const [displayInfo, setDisplayInfo] = useState(false);

  // const changeDisplay = () => {
  //   setDisplayInfo((prev) => !prev);
  //   setTimeout(() => {
  //     const allLanguages = document.querySelectorAll(
  //       ".languagesContainer > span"
  //     );
  //     let delay = 0;
  //     allLanguages.forEach((language) => {
  //       delay += 0.;
  //       language.style.animationDelay = `${delay}s`;
  //     });
  //   }, 100);
  // };

  return (
    <div className="info-container">
      <div className="info-box">
        <div className="info-firstRow">
          <div className="info borderRight">
              <i className="fas fa-server"></i>
              <h1>BACK-END</h1>
              <span>Python</span>
              <span>C++</span>
              <span>C#</span>
          </div>
          <div className="info borderRight">
            <i className="fas fa-desktop"></i>
            <h1>FRONT-END</h1>
            <span>JavaScript</span>
            <span>TypeScript</span>
            <span>HTML</span>
            <span>CSS</span>
          </div>
          <div className="info">
            <i className="fas fa-database"></i>
            <h1>DATABASE</h1>
            <span>SQL</span>
            <span>SQLite</span>
            <span>MongoDB</span>
          </div>
        </div>
        <div className="info-secondRow">
          <div className="info borderRight">
              <h1>FRAMEWORKS & LIBRARIES</h1>
              <span>React</span>
              <span>Next.js</span>
              <span>Node.js</span>
              <span>Express</span>
              <span>Tailwind CSS</span>
              <span>Bootstrap</span>
              <span>Django</span>
            </div>
            <div className="info">
              <h1>DEPLOYMENT</h1>
              <span>Amazon Web Services</span>
              <span>S3</span>
              <span>CloudFront</span>
              <span>Route53</span>
              <span>Certificate Manager</span>
              <span>Vercel</span>
            </div>
        </div>
      </div>
    </div>
  );
};
