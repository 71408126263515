import React, { useState, useEffect } from "react";

export default ( projectObj ) => {
  const { project } = projectObj;
  const [showOverlayProject, setShowOverlayProject] = useState(false);
  const [color, setColor] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const seeProject = () => {
    setShowOverlayProject(true);
    projectObj.changeOverlayInitiatedStatus(true)
  };

  const removeProject = () => {
    setShowOverlayProject(false);
    projectObj.changeOverlayInitiatedStatus(false)
  }
  // if (showOverlayProject) {
  //   setTimeout(() => {
  //     const exitProjectBtn = document.querySelector(".exit-project-btn");
  //     exitProjectBtn.addEventListener("click", () => {
  //       setShowOverlayProject(false);
  //     });
  //   }, 500);
  // }

  const mouseEnter = (e) => {
    setColor(true);
    setProjectDetails(true);
  };

  const mouseLeave = (e) => {
    setColor(false);
    setProjectDetails(false);
  };

  return (
    <div className="projectContainer">
      <div
        className={color ? `project ${project.container_class}` : "project"}
        onMouseEnter={(e) => mouseEnter(e)}
        onMouseLeave={(e) => mouseLeave(e)}
      >
        <h1>{projectDetails ? project.subtitle : project.name}</h1>
        <i className={`${project.icon}`}></i>
        {projectDetails && !project.inProgress && (
          <button onClick={() => seeProject()} className="hover-demo-button">
            <i className="fas fa-play-circle"></i> Demo
          </button>
        )}
        {projectDetails && project.inProgress && (
          <a
            className="hover-demo-button"
            href={project.github_link}
            target="_blank"
          >
            <span>Source Code</span>
          </a>
        )}
      </div>
      {showOverlayProject && (
        <div className="selected-container">
          <div className="selected">
            {project.preview ? <img className="gif_demo" src={project.preview} /> : <div className="gif_demo unavailable_preview">Preview not available...</div>}
            <div className="project-info-container">
              <div onClick={() => removeProject()} className="exit-project-btn">
                <i className="fas fa-times"></i>
              </div>
              <h1>{project.name}</h1>
              <span>{project.description}</span>
              {project.qr && (
                <div className="qr-container">
                  <div className="project-badges">
                    {project.weatherBadges.map((x) => {
                      return <i className={x}></i>;
                    })}
                  </div>
                  <div className="qr">
                    <img className="project-qr" src={project.qr} />
                    <span className="project-qr-subtitle">Scan with Expo</span>
                  </div>
                </div>
              )}
              {project.badges && (
                <div className="project-badges">
                  {project.badges.map((x) => {
                    return <i className={x}></i>;
                  })}
                  {project.pug && (
                    <img className="img-badge" src={project.pug} />
                  )}
                  {project.sequelize && (
                    <img className="img-badge" src={project.sequelize} />
                  )}
                  {project.firebase && (
                    <img className="img-badge" src={project.firebase} />
                  )}
                </div>
              )}
              {/* <span className="project-focus">Focus: {project.focus}</span> */}

              <div className="buttons-container">
                <a target="_blank" href={project.github_link}>
                  <i class="fab fa-github"></i>
                  <span>Source Code</span>
                </a>
                {project.live_link && (
                  <a target="_blank" href={project.live_link}>
                    <i class="fas fa-play-circle"></i>
                    <span>Live Demo</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
