import React from "react";

export default () => {
  return (
    <>
      <div id="about"></div>
      <div className="header-container">
        <header>
          <h1 className="header-title">Hi, I'm Renzo</h1>
          <h2 className="header-subtitle">I like to create things.</h2>
        </header>
      </div>
    </>
  );
};
