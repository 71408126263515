import React, { useState, useEffect } from "react";
import Project from "./Project";
const { clones } = require("../data/clones.json");
const { javascript } = require("../data/javascript.json");
const { python } = require("../data/python.json");
const { next } = require("../data/next.json");
const { react } = require("../data/react.json");
const { typescript } = require("../data/typescript.json");

export default ({ changeOverlayInitiatedStatus }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    console.log(changeOverlayInitiatedStatus)
    const reactBtn = document.querySelector(".projects-nav-btn");
    reactBtn.classList.add("nav-active");
    displayProjects(react);
  }, []);

  const displayProjects = (projectsArray, e) => {
    setProjects(projectsArray);
    if (e) {
      const projectsNavBtns = document.querySelectorAll(".projects-nav-btn");
      for (let i = 0; i < projectsNavBtns.length; i++) {
        if (projectsNavBtns[i].textContent == e.target.textContent) {
          projectsNavBtns[i].classList.add("nav-active");
        } else {
          projectsNavBtns[i].classList.remove("nav-active");
        }
      }
    }
  };

  return (
    <section className="projects">
      <div className="projects-nav-container">
        <div className="projects-nav">
        
          <button
            className="projects-nav-btn"
            onClick={(e) => displayProjects(react, e)}
          >
            React
          </button>
          
          <button
            onClick={(e) => displayProjects(javascript, e)}
            className="projects-nav-btn"
          >
            JavaScript
          </button>
          <button
            onClick={(e) => displayProjects(typescript, e)}
            className="projects-nav-btn"
          >
            TypeScript
          </button>
          <button
            onClick={(e) => displayProjects(python, e)}
            className="projects-nav-btn"
          >
            Python
          </button>
          <button
            onClick={(e) => displayProjects(clones, e)}
            className="projects-nav-btn"
          >
            Clones
          </button>
        </div>
      </div>
      <div className="projects-container">
        {projects.map((project) => {
          return <Project changeOverlayInitiatedStatus={changeOverlayInitiatedStatus} key={project.id} project={project} />;
        })}
      </div>
    </section>
  );
};
