import React from "react";

import DisplayProjects from "./Display-Projects";

export default ({ changeOverlayInitiatedStatus }) => {
  return (
    <>
      <div id="projects"></div>
      <div className="projects-header-container">
        <h1>Projects</h1>
        <h3>These are the projects that i've built over the past two years</h3>
      </div>
      <DisplayProjects changeOverlayInitiatedStatus={changeOverlayInitiatedStatus} />
    </>
  );
};
