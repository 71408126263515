import React from "react";

import Info from "./Info";

export default () => {
  return (
    <>
      <div className="icon-divider">
        <img src="/images/icons.png" />
      </div>
      <div className="about-container">
        <h1>A little bit about my journey.</h1>
        <p>
        I started my journey by taking courses from online learning platforms like Udemy and Nomad Coders. Shortly after, I enrolled in an online programming bootcamp and then graduated with a Full Stack JavaScript Techdegree from Team Treehouse. To further expand my skills, I am currently pursuing a Computer Information Systems Diploma at Kwantlen Polytechnic University in British Columbia, Canada. 
        </p>
      </div>
      <Info />
    </>
  );
};
